






import {Component, Vue} from "vue-property-decorator";
import dayjs from "dayjs";

@Component<DatesSelector>({
  watch: {
    datesRange(value) {
      if (!value) return;
      this.dayFrom = dayjs(value[0]);
      this.dayTo = dayjs(value[1]);
      this.emitDates()
    }
  }
})
export default class DatesSelector extends Vue {
  datesRange: string | null = null;

  dayFrom: dayjs.Dayjs = dayjs().year(dayjs().year() - 1)
  dayTo: dayjs.Dayjs = dayjs()

  private emitDates() {
    this.$emit("changed", {
      dayFrom: this.dayFrom,
      dayTo: this.dayTo
    })
  }
}
