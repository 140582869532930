





















































import {Component, Vue} from 'vue-property-decorator';
import lkdr from "@/apiclients/lkdr";

@Component<App>({
  mounted() {
    lkdr.onAuthStateChanged(it => {
      this.$store.commit("lkdr/setUserProfile", it)
    })
  }
})
export default class App extends Vue {
}
