

















import {Component, Vue} from 'vue-property-decorator';
import lkdr from "@/apiclients/lkdr";
import {TaxpayerPerson} from "@/apiclients/lkdr/LkdrAuthorizedApiClient";

@Component<Me>({})
export default class Me extends Vue {

  get person(): TaxpayerPerson | null {
    return this.$store.state.lkdr.userProfile
  }

  login(): void {
    lkdr.doAuth()
      .auth(prompt("Your number (79XXXXXXXXX)", "") || "")
      .otp(() => prompt("SMS Code", "") || "")
  }

  logout(): void {
    lkdr.logout()
  }

}
